export default {
    Global: {
        medicalKit: "Medical Kit",
        tehnicalInspection: "Tehnical Inspection",
        insurance: "Insurance",
        extinguisher: "Extinguisher",
        vignette: "Vignette",
    },
    Form: {
        FirstName: "First Name",
        FirstNameError: "Please enter a valid first name",
        FirstNamePlaceHolder: "John",
        LastName: "Last Name",
        LastNameError: "Please enter a valid last name",
        LastNamePlaceHolder: "Doe",
        Phone: "Phone",
        PhoneError: "Please enter a valid phone",
        PhonePlaceHolder: "0040 771 333 333",
        Password: "Password",
        PasswordError: "Please enter a stronger password",
        PasswordPlaceHolder: "Some Password",
        ReTypePassword: "Retype Password",
        ReTypePasswordError: "The retype passwords must be equal",
        ReTypePasswordPlaceHolder: "The password and retype must be equal",
        Email: "Email",
        EmailError: "Please enter a valid email",
        EmailPlaceHolder: "example@example.com",
        CarNumber: "Car Number",
        CarNumberPlaceHolder: "CJ-85-MDL",
        CarNumberError: "Please enter a valid car number",
        Brand: "Brand",
        BrandPlaceHolder: "Audi",
        BrandError: "Please enter a valid description",
        DatePlaceHolder: "15.06.2021",
        Description: "Description",
        DescriptionPlaceHolder: "Some description... ",
        DescriptionError: "Please enter a valid description",
        InsuranceExpiration: "Insurance Expiration",
        VignetteExpiration: "Vignette Expiration",
        TehnicalInspectionExpiration: "Tehnical Inspection Expiration",
        ExtinguisherExpiration: "Extinguisher Expiration",
        MedicalKitExpiration: "Medical Kit Expiration",
        ProfileSaved: "Profile Saved",
        Profile: "Profile",
        Car: "Car",
        CarSaved: "Car saved"
    },
    ScreenTitle: {
        Home: "Home",
        Add: "Add",
        Cars: "Cars",
        CarDetails: "Car Details",
        Edit: "Edit",
        Profile: "Profile",
    },
    Button: {
        Login: "Login",
        Save: "Save",
        Register: "Register",
        ForgotPassword: "Forgot Password?",
        Ok: "Ok"
    },
    Navigation: {
        Home: "Home",
        Add: "Add",
        Cars: "Cars",
        Edit: "Edit",
        Profile: "Profile",
        ExpirationList: "Expiration Lista",
        Login: "Login",
        Logout: "Log out",
        Register: "Register",
    },
    Login: {
        Failed: "Login Failed",
        Description: "Please check your credentials",
        CheckEmailForPassword: "Please check your email for the new password",
        BackToLogin: "Back to Login",
    }
}