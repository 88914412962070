import React from 'react';
import RegistrationForm from "./RegistrationForm";
import FacebookLoginButton from "../../common/Facebook/FacebookLogin";

const Registration = () => (
    <>
        <FacebookLoginButton />
        <RegistrationForm />
    </>
);

export default Registration;
