import { toast } from "react-toastify";

export const notify = ( message, type ) => {
    switch( type ) {
        case "info":
            toast.info( message );
            break;
        case "success":
            toast.success( message );
            break;
        case "warning":
            toast.warn(message);
            break;
        default:
            toast.error( message );
    };
};
