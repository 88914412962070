import { translate } from "./translation/index";

const routes = {
    "/": translate("Navigation:Home"),
    "/cars": translate("Navigation:Cars"),
    "/profile": translate("Navigation:Profile"),
    "/login": translate("Navigation:Login"),
    "/registration": translate("Navigation:Register"),
    "/add-car": translate("Navigation:AddCar"),
    "/expiring": translate("Navigation:ExpirationList")
}

const getPageTitle = ( route ) => routes[route] ? routes[route] : translate("Navigation:Home");

export default getPageTitle;