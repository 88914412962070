import React, { useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { useGlobalState } from "../../../state/useGlobalState";
import getPageTitle from "../../../utilities/getPageTitle";
import { deleteToken, getToken } from "../../../utilities/localStorageHelper";
import { translate } from  "../../../utilities/translation";

import MobileMenu from "./MobileNav";

const Header = ({ history, location }) => {
    const [ loggedInState, dispatch ] = useGlobalState();

    const handleLoggout = ( ) => {
        deleteToken();
        history.push("/login");
        dispatch( { type: "LOGOUT"})
    }

    const menuItems = loggedInState ? [
            <NavLink to="/" className="menu-item" key="/">
                { translate("Navigation:Home") }
            </NavLink>,
            <NavLink to="/contact" className="menu-item" key='/contact'>
            Contact
            </NavLink>,
            <NavLink to="/expiring" className="menu-item" key='/expiring'>
                { translate("Navigation:ExpirationList")}
            </NavLink>,
            <NavLink to="/cars" className="menu-item" key='/cars'>
                { translate("Navigation:Cars")}
            </NavLink>,
            <NavLink to="/profile" className="menu-item" key='/profile'>
                { translate("Navigation:Profile")}
            </NavLink>,
            <a onClick={handleLoggout} className="menu-item" key='/logout'>
                { translate("Navigation:Logout")}
            </a>
    ] : [
            <NavLink to="/" className="menu-item" key='/'>
            { translate("Navigation:Home") }
            </NavLink>,
            <NavLink to="/registration" className="menu-item" key='/registration'>
            { translate("Navigation:Register") }
            </NavLink>,
            <NavLink to="/contact" className="menu-item" key='/contact'>
            Contact
            </NavLink>,
            <NavLink to="/login" className="menu-item" key='/login'>
            { translate("Navigation:Login") }
            </NavLink>,
    ];

    const screenWidth = window.innerWidth || 1000;
    return (
        <div className="header-wrapper">
            <div className="header-content">
            { screenWidth > 600 && <>
                <div className="logo-container">
                <h2>Expir</h2>
                <img src="/images/logo.png" width="40px" height="40px" />
                {/* <h3>{getPageTitle(location.pathname)}</h3> */}
                </div>
                <div className="navigation-container">
                    { menuItems }
                </div>
            </>
            } 
            {
                screenWidth < 600 && <>
                <div className="logo-container-mobile">
                    <div className="logo">
                        <h2>Expir</h2>
                        <img src="/images/logo.png" width="40px" height="40px" />
                    </div>
                    <div className="navigation-container">
                        <MobileMenu menuItems={menuItems}/>
                    </div>
                </div>
                </>
            }
            </div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default withRouter(Header);
