import React, { useState } from 'react';
import { withRouter } from "react-router-dom";

import { required, maxLength16, maxLength256 } from "../../../utilities/formValidations";
import { saveCar } from "../../../utilities/api";
import { dateStringToTimestamp } from "../../../utilities/helpers";
import { translate } from  "../../../utilities/translation"
import { notify } from  "../../../utilities/notifications"

import TextInputField from '../../common/formFields/TextInputField';
import DateInputField from '../../common/formFields/DateInputField';
const expiring = [ "insurance", "vignette", "tehnicalInspection", "extinguisher", "medicalKit"];

const CarForm = ({history}) => {
    const [ screenSize, setScreenSize ] = useState( window.innerWidth );
    const [ carDetails, setCarDetails ] = useState( {
        carNumber: '',
        brand: '',
        insurance: '',
        vignette: '',
        tehnicalInspection: '',
        extinguisher: '',
        medicalKit: '',
        errors: [ "carNumber" ]
    } );

    const handleFieldChange = ( property, value, error ) => {
        const newDetails = Object.assign( carDetails );
        newDetails[ property ] = value;
        const hasError = newDetails['errors'].find( field => property===field)
        if( error && !hasError ) {
            newDetails["errors"].push( property );
        }
        if( !error && hasError ) {
            newDetails["errors"] = newDetails["errors"].filter( field => field !== property );
        }
        setCarDetails( newDetails );
    };

    window.addEventListener( 'resize', () => {
        setScreenSize( window.innerWidth );
    } );

    const handleSave = ( ) => {
        const { errors, ...car } = carDetails;
        if( !errors.length ) {                   
            saveCar( car ).then( 
                ( succes ) => {
                    history.push( "/cars");
                    notify( translate("Notifications:CarSaved"), "success");
                },
                ( error ) => {
                    console.log( "there was an error ", error );
                    notify( translate("Notifications:CarSaveFailed"), "warning")
                }
            )
        } else {
            notify( translate("Notifications:CheckFormFields"), "warning")
        }
    }

    const widthStyle = screenSize < 660 ? { padding: 0, marginLeft: 0, marginRight: 0 } : null;

    return (
        <div className="form-container">
            <div className="text-fields-container" style={widthStyle}>
                <TextInputField
                    fieldLabel={translate("Form:CarNumber")}
                    screenSize={screenSize}
                    handleFieldChange={handleFieldChange}
                    fieldName="carNumber"
                    validations={ [ required, maxLength16 ] }
                    placeholder="CJ-85-MDL"
                    required
                />
                <TextInputField
                    fieldLabel={translate("Form:Brand")}
                    screenSize={screenSize}
                    handleFieldChange={handleFieldChange}
                    fieldName="brand"
                    validations={ [ maxLength16 ] }
                    placeholder="Ford"
                />
            </div>
            <div className="date-field-container" style={widthStyle}>
                <DateInputField
                    fieldLabel={translate("Form:InsuranceExpiration")}
                    screenSize={screenSize}
                    handleFieldChange={handleFieldChange}
                    fieldName="insurance"
                />
                <DateInputField
                    fieldLabel={translate("Form:VignetteExpiration")}
                    screenSize={screenSize}
                    handleFieldChange={handleFieldChange}
                    fieldName="vignette"
                />
                <DateInputField
                    fieldLabel={translate("Form:TehnicalInspectionExpiration")}
                    screenSize={screenSize}
                    handleFieldChange={handleFieldChange}
                    fieldName="tehnicalInspection"
                />
                <DateInputField
                    fieldLabel={translate("Form:ExtinguisherExpiration")}
                    screenSize={screenSize}
                    handleFieldChange={handleFieldChange}
                    fieldName="extinguisher"
                />
                <DateInputField
                    fieldLabel={translate("Form:MedicalKitExpiration")}
                    screenSize={screenSize}
                    handleFieldChange={handleFieldChange}
                    fieldName="medicalKit"
                />
            </div>
            { translate("Form:Required")}
            <div>
            <button
                className="btn-add-car"
                onClick={handleSave}
            >
               {translate("Button:Save") }
            </button>
            </div>
        </div>
    );
};

export default withRouter(CarForm);
