import React from 'react';
import FacebookLoginWithButton from 'react-facebook-login';
import {
    withRouter
  } from 'react-router-dom'
import { facebookLogin } from "../../../utilities/api";
import { useGlobalState } from "../../../state/useGlobalState";
import { notify } from '../../../utilities/notifications';
import { translate } from  "../../../utilities/translation"

const FacebookLoginButton = ( { history }) => {
    const [ loggedInState, dispatch ] = useGlobalState();
    const facebookResponse = ( response ) => {
        facebookLogin( response )
        .then(
            ( succes ) => {
                dispatch( { type: "LOGIN" });
                history.push("/cars");
                notify(translate("Notifications:LoginSuccessfull"), "success" );
            },
            ( error ) => {
                notify( translate("Notifications:LoginFailed"), "warning");
                console.log( "there was an error ", error )
            }
        )
    }

    return <div className='facebook-login-wrapper'>
        <FacebookLoginWithButton
            appId="473287330237073"
            fields="email,first_name,last_name,picture"
            scope="email"
            disableMobileRedirect={true}
            redirectUri="https://expiro.ro/privacy-policy"
            callback={facebookResponse}
            icon="fa-facebook"/>
        <div className="or">---------- sau ----------</div>
</div>
};

export default withRouter(FacebookLoginButton);