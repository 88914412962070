import React from 'react';
import { withRouter} from "react-router-dom";
import { getCars } from "../../../utilities/api";
import CarList from "./CarList";
import CarListMobile from "./CarListMobile";
import { translate } from '../../../utilities/translation';

class Cars extends React.Component {
    state = {
        cars: [],
        mobileView: null,
        loading: false,
    }

    componentDidMount( ) {
        this.setState( { loading: true } );
        getCars( ).then( 
            (sucess) => {
                this.setState( {
                    cars: sucess.data,
                    mobileView: window.innerWidth < 600,
                    loading: false,
                } );
            },
            error => {
                this.setState( { loading: false } );
            }
        )
    }

    handleAddCarAction = ( ) => {
        const { history } = this.props;
        history.push("/add-car");
    }

    render( ) {
        const { cars, mobileView, loading } = this.state;
        const Component = mobileView ? CarListMobile : CarList;
        const carsList = cars.length ? <Component list={this.state.cars} /> : "Nu ai nici o mașină adăugată";
        return (
            <div className="expiring-container">
                <div className="add-car-wrapper"><button className="btn-add-car" onClick={this.handleAddCarAction}>{ translate( "Button:AddCar")}</button> </div>
                { loading && <img src="images/loading.gif" />}
                { !loading && carsList }
            </div>
        )
    }
}

export default withRouter(Cars);
