import i18next, { lng, reInit } from "./i18n";

const translate = i18next.getFixedT(i18next.language);

const setLng = ( lang ) => {
    i18next.changeLanguage(lang);
    window.langg = i18next;
  }

export {
    translate,
    setLng,
    i18next,
    reInit
}