import React from 'react';
import CarForm from "./CarForm";
import { translate } from "../../../utilities/translation";

const AddCar = ( ) => (
    <div className="expiring-container">
        <h1>{ translate("Navigation:AddCar")}</h1>
        <CarForm />
    </div>
);

export default AddCar;
