import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { timestampToDateString } from "../../../utilities/helpers";
import { translate } from "../../../utilities/translation";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#F28444',
    color: "white",
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: '1px',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: "rgb(242, 132, 68, 0.1)",
    },
    margin: "15px",
    backgroundColor: "rgb(107, 167, 191, 0.2)",
  },
}))(TableRow);

function createData( { id, carNumber, brand, extinguisher, medicalKit, vignette, insurance, tehnicalInspection } ) {
  
  return {
    id,
    carNumber,
    brand,
    extinguisher: extinguisher ? timestampToDateString(extinguisher): "-",
    medicalKit: medicalKit ? timestampToDateString(medicalKit): "-",
    vignette: vignette ? timestampToDateString(vignette): "-",
    insurance: insurance ? timestampToDateString(insurance): "-",
    tehnicalInspection: tehnicalInspection ? timestampToDateString(tehnicalInspection): "-",
   };
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 400,
  },
  headerImage: {
},
headerEvent: {
  fontSize: 12,
}
}));

const CarList = ({ list, history }) => {
  const classes = useStyles();
  const rows = list.map( event => createData(event) );
  const getImage = ( name )=> <img src={`/images/${name}.png`} width="23px" height="23px" alt={name}/>
  const handleCarClick = ( id ) => () => history.push(`/edit-car/${id}`);
  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">
                <div>{translate("Form:CarNumber")}</div>
                <div height="25px">{" "}</div>
            </StyledTableCell>
            <StyledTableCell align="center">
                <div className={classes.headerImage}>{getImage("vignette-white")}</div>
                <div className={classes.headerEvent}>{translate("Global:vignette")}</div>
            </StyledTableCell>
            <StyledTableCell align="center">
                <div className={classes.headerImage}>{getImage("tehnical-inspection-white")}</div>
                <div className={classes.headerEvent}>{translate("Global:tehnicalInspection")}</div>
            </StyledTableCell>
            <StyledTableCell align="center">
                <div className={classes.headerImage}>{getImage("medical-kit-white")}</div>
                <div className={classes.headerEvent}>{translate("Global:medicalKit")}</div>
            </StyledTableCell>
            <StyledTableCell align="center">
                <div className={classes.headerImage}>{getImage("extinguisher-white")}</div>
                <div className={classes.headerEvent}>{translate("Global:extinguisher")}</div>
            </StyledTableCell>
            <StyledTableCell align="center">
                <div className={classes.headerImage}>{getImage("insurance-white")}</div>
                <div className={classes.headerEvent}>{translate("Global:insurance")}</div>
            </StyledTableCell>
            <StyledTableCell align="center">
            <div className={classes.headerImage}>{getImage("edit-white")}</div>
                <div className={classes.headerEvent}>{translate("Global:Edit")}</div>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <StyledTableRow key={`${row.carNumber}`}>
              <StyledTableCell component="th" scope="row" align="center">
                <strong  onClick={ handleCarClick(row.id) }>{row.carNumber}</strong>
              </StyledTableCell>
              <StyledTableCell align="center">{row.vignette}</StyledTableCell>
              <StyledTableCell align="center">{row.tehnicalInspection}</StyledTableCell>
              <StyledTableCell align="center">{row.medicalKit}</StyledTableCell>
              <StyledTableCell align="center">{row.extinguisher}</StyledTableCell>
              <StyledTableCell align="center">{row.insurance}</StyledTableCell>
              <StyledTableCell align="center">
                <button className='btn-add-car' onClick={ handleCarClick(row.id) }>
                  Edit
                </button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default withRouter( CarList );