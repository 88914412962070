import React, { useState, useEffect } from 'react';
import {
    withRouter
  } from 'react-router-dom'

import { validEmail, minLengthSix } from "../../../utilities/formValidations";
import TextInputField from '../../common/formFields/TextInputField';
import { login } from "../../../utilities/api";
import { translate } from  "../../../utilities/translation"
import { useGlobalState } from "../../../state/useGlobalState";
import { notify } from '../../../utilities/notifications';

const LoginForm = ( { history } ) => {
    const [ loginDetails, setLoginDetails ] = useState( {
        email: "",
        password: "",
        errors: ["email"]
    } );

    const [ state, dispatch ] = useGlobalState();

    const handleFieldChange = ( property, value, error ) => {
        const newDetails = Object.assign( loginDetails );
        newDetails[ property ] = value;
        const hasError = newDetails['errors'].find( field => property===field)
        if( error && !hasError ) {
            newDetails["errors"].push( property );
        }
        if( !error && hasError ) {
            newDetails["errors"] = newDetails["errors"].filter( field => field !== property );
        }
        setLoginDetails( newDetails );
    };

    const handleSave = ( ) => {
        if( !loginDetails.errors.length ) {                     
            login( loginDetails ).then( 
                ( succes ) => {
                    dispatch( { type: "LOGIN" });
                    notify(translate("Notifications:LoginSuccessfull"), "success" );
                },
                ( error ) => {
                    notify( translate("Notifications:LoginFailed"), "warning");
                    console.log( "there was an error ", error )
                }
            )
            // console.log( loginDetails );
        }
    }

    useEffect( ( ) => {
        if( state ) {
            history.push("/cars");
        }
    },[state])

    return (
        <div className="form-container">
            <div className="text-fields-container">
                <h1>Login</h1>
                <TextInputField
                    fieldLabel="Email"
                    handleFieldChange={handleFieldChange}
                    fieldName="email"
                    validations={ [ validEmail ] }
                    required
                />
                <TextInputField
                    fieldLabel={ translate("Form:Password")}
                    handleFieldChange={handleFieldChange}
                    fieldName="password"
                    type="password"
                    validations={ [ minLengthSix ] }
                    required
                />
                {translate("Form:Required")}
            </div>
            <div>
                <button
                    className="btn-add-car"
                    onClick={handleSave}
                >
                Login
                </button>
                <div>
                <br/>
                    <br/>
                    <a href="/reset-password">{translate("Button:ForgotPassword")}</a>
                </div>
            </div>
        </div>
    );
};

export default withRouter(LoginForm);
