import React, {useEffect} from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGlobalState } from "../state/useGlobalState";
import { getUserDetails } from "../utilities/api";
import PagesContent from './PagesContent';
import Header from './common/header/Header';
import Footer from './common/Footer/Footer';

const AppContent = () => {
      const [ state, dispatch ] = useGlobalState();

      useEffect( () => {
        const checkTokenValidity = async () => { 
            await getUserDetails()
                .then(
                ( success ) => {
                    dispatch( { type: "LOGIN" })
                },
                ( error ) => {
                    dispatch( { type: "LOGGOUT" })
                }
            )
        }
        checkTokenValidity();
    }, []);
    return (
        <div>
            <ToastContainer />
            <Header />
            <div className="container">
                <PagesContent />
            </div>
            <Footer />
        </div>
    
    );
}

export default AppContent;
