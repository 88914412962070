import React from 'react';
import EditProfile from "./EditProfile";

const Profile = () => (
    <div>
        <EditProfile />
    </div>
);

export default Profile;
