import React, { Component, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppContent from './components/AppContent';
import { Provider } from "./state/useGlobalState";
import { reducer } from "./state/reducer";


const App = ( { initialState = null } ) => {
    return (
        <BrowserRouter>
            <Provider reducer={reducer} initialState={initialState}>
                <AppContent />
            </Provider>
        </BrowserRouter>
    );
};

export default App;
