import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact/Contact';
import Registration from './pages/Registration/Registration';
import Login from "./pages/Login/Login";
import Profile from './pages/Profile/Profile';
import Expiring from "./pages/Expiring/Expiring";
import Cars from './pages/Cars/';
import AddCar from "./pages/Cars/AddCar";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ResetPasswordRequest from "./pages/Profile/ResetPasswordForm";
import { useGlobalState } from "../state/useGlobalState";
import EditCarForm from './pages/Cars/EditCarForm';

class PrivateRoute extends React.Component {
    render() {
      const { children, loggedIn } = this.props;
      return (
        <Route
          render={props =>
            {
              if( loggedIn === null ) {
                return null;
              }
              if( loggedIn ) {
                return <>{children}</>;
              }

              return <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
            }
          }
        />
      );
    }
  }

const PagesContent = () => {
  const [ state, dispatch ] = useGlobalState();

  return (
      <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/registration" component={Registration} />
          <Route exact path="/reset-password" component={ResetPasswordRequest} />
          <Route exact path="/terms-and-conditions" component={Terms} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <PrivateRoute loggedIn={state}>
            <Switch>
                <Route exact path="/cars" component={Cars} />
                <Route exact path="/add-car" component={AddCar} />
                <Route exact path="/edit-car/:carId" component={EditCarForm} />
                <Route exact path="/profile" component={Profile} />
                <Route exact path="/expiring" component={Expiring} />
            </Switch>
          </PrivateRoute>
      </Switch>
);
}
export default PagesContent;
