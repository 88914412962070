import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";

import { required, maxLength16, maxLength256 } from "../../../utilities/formValidations";
import { editCar, getCarById, deleteCar } from "../../../utilities/api";
import { dateStringToTimestamp } from "../../../utilities/helpers";
import { translate } from  "../../../utilities/translation"
import { notify } from "../../../utilities/notifications";

import TextInputField from '../../common/formFields/TextInputField';
import DateInputField from '../../common/formFields/DateInputField';

const EditCarForm = ({ history, match }) => {
    const [ screenSize, setScreenSize ] = useState( window.innerWidth );
    const [ carDetails, setCarDetails ] = useState( {
        carNumber: '',
        brand: '',
        insurance: '',
        vignette: '',
        tehnicalInspection: '',
        extinguisher: '',
        medicalKit: '',
        id: '',
        errors: [ ]
    } );

    useEffect( ( ) => {
        const fetchData = async () => {
         const details = await getCarById( match.params.carId );
         const { carNumber, brand, insurance, vignette, tehnicalInspection, extinguisher, medicalKit, id } = details.data;
         const newDetails = Object.assign( {}, carDetails, { carNumber }, { brand }, { insurance }, { vignette }, { tehnicalInspection }, { extinguisher }, { medicalKit }, { id }  );
         console.log( "new Details ", newDetails );
         setCarDetails( newDetails );
        };
        fetchData();
     }, [])  

    const handleFieldChange = ( property, value, error ) => {
        const newDetails = Object.assign({}, carDetails );
        newDetails[ property ] = value;
        const hasError = newDetails['errors'].find( field => property===field)
        if( error && !hasError ) {
            newDetails["errors"].push( property );
        }
        if( !error && hasError ) {
            newDetails["errors"] = newDetails["errors"].filter( field => field !== property );
        }
        setCarDetails( newDetails );
    };

    window.addEventListener( 'resize', () => {
        setScreenSize( window.innerWidth );
    } );

    const handleSave = ( ) => {
        const { errors, ...car } = carDetails;
        if( !errors.length ) {                   
            editCar( car, car.id ).then( 
                ( succes ) => {
                    history.push( "/cars");
                    notify( translate("Notifications:CarSaved"), "success");
                },
                ( error ) => {
                    notify( translate("Notifications:CarSavedFailed"), "warning" );
                    console.log( "there was an error ", error );
                }
            )
        } else {
            notify( translate("Notifications:CheckFormFields"), "warning" );
        }
    }

    const handleCarDelete = ( id ) => {
        deleteCar( match.params.carId ).then(
            ( succes ) =>{
            notify( translate("Notifications:CarDeletedSuccessfull"), "success");
             history.push("/cars");
            },
            ( error ) => {
                notify( translate("Notifications:CarDeletedFailed"), "warning");
                console.log( "there was an error on car delete ", error );
            }
        );
    }

    const widthStyle = screenSize < 660 ? { padding: 0, marginLeft: 0, marginRight: 0 } : null;
    const { carNumber, brand, description, insurance, vignette, tehnicalInspection, extinguisher, medicalKit } = carDetails;
    return (
        <div className="form-container">
            <div className="text-fields-container" style={widthStyle}>
                <TextInputField
                    fieldLabel={translate("Form:CarNumber")}
                    screenSize={screenSize}
                    handleFieldChange={handleFieldChange}
                    fieldName="carNumber"
                    validations={ [ required, maxLength16 ] }
                    placeholder="CJ-85-MDL"
                    value={carNumber}
                />
                <TextInputField
                    fieldLabel={translate("Form:Brand")}
                    screenSize={screenSize}
                    handleFieldChange={handleFieldChange}
                    fieldName="brand"
                    validations={ [ maxLength16 ] }
                    placeholder="Ford"
                    value={brand}
                />
            </div>
            <div className="date-field-container" style={widthStyle}>
                <DateInputField
                    fieldLabel={translate("Form:InsuranceExpiration")}
                    screenSize={screenSize}
                    handleFieldChange={handleFieldChange}
                    fieldName="insurance"
                    value={insurance}
                />
                <DateInputField
                    fieldLabel={translate("Form:VignetteExpiration")}
                    screenSize={screenSize}
                    handleFieldChange={handleFieldChange}
                    fieldName="vignette"
                    value={vignette}
                />
                <DateInputField
                    fieldLabel={translate("Form:TehnicalInspectionExpiration")}
                    screenSize={screenSize}
                    handleFieldChange={handleFieldChange}
                    fieldName="tehnicalInspection"
                    value={tehnicalInspection}
                />
                <DateInputField
                    fieldLabel={translate("Form:ExtinguisherExpiration")}
                    screenSize={screenSize}
                    handleFieldChange={handleFieldChange}
                    fieldName="extinguisher"
                    value={extinguisher}
                />
                <DateInputField
                    fieldLabel={translate("Form:MedicalKitExpiration")}
                    screenSize={screenSize}
                    handleFieldChange={handleFieldChange}
                    fieldName="medicalKit"
                    value={medicalKit}
                />
                <div className="buttons">
                    <button 
                            className="btn-add-car delete" 
                            onClick={handleCarDelete}>
                            { translate("Button:DeleteCar") }
                    </button>
                    <button
                        onClick={handleSave}
                        className="btn-add-car"
                    >
                    {translate("Button:Save") }
                    </button>
                </div>
            </div>

        </div>
    );
};

export default withRouter(EditCarForm);
