import isEmail from "validator/lib/isEmail";

const minLength = (length) => ( text ) => text.length >= length ? "" : "prea scurt";
const minLengthSix = minLength(6);
const maxLength = ( length ) => ( text ) => text.length <= length ? "": "prea lung";
const maxLengthEight = maxLength(8);
const maxLength16 = maxLength(16);
const maxLength256 = maxLength(256);
const maxLength4048 = maxLength(4048);
const required = (text) => text ? "": "* necesar";

const validEmail = ( email ) => isEmail(email) ? "" : "Doar adrese de email valide example@example.com";

const alphanumeric = ( text ) => alphanumeric.test(text) ? "" : "Only caracters, numbers and - . are allowed";


export {
    minLengthSix,
    maxLengthEight,
    maxLength16,
    maxLength256,
    maxLength4048,
    validEmail,
    alphanumeric,
    required,
}