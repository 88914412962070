import React from 'react';
import { translate } from "../../utilities/translation";

const DateComponent = ( { date = 7 } ) => (
  <div className="date-wrapper">
    <div className="header" />
    <div className="content">
    { date }
    </div>
  </div>
);

const AlertType = ( { name = 7 } ) => {
  const imageMapping = {
    "vignette": "vignette-blue",
    "medicalKit": "medical-kit-blue",
    "tehnicalInspection": "tehnical-inspection-blue",
    "insurance": "insurance-blue",
    "extinguisher": "extinguisher-blue"
  }
  const getImage = ( name )=> <img src={`/images/${imageMapping[name]}.png`} width="40px" height="40px" alt={name}/>
  return (
  <div className="alert-type-wrapper">
    <div className="header">
       { getImage(name) }
    </div>
    <div className="content">
    { translate( `Global:${name}`) }
    </div>
  </div>
)};

const Home = () => {
    return (
        <div className='homepage'>
            <h1 className="home">Alerte expirare mașină</h1>
            <div className='alerts'>
              <AlertType name='tehnicalInspection'/>
              <AlertType name='insurance'/>
              <AlertType name='vignette'/>
              <AlertType name='extinguisher'/>
              <AlertType name='medicalKit'/>
            </div>
            <div className='cta-wrapper'>
                <button className='btn-add-car'><a href='/registration'>Înregistrare</a></button>
              </div>
            <h2 className="home">Primești email de notificare înainte cu:</h2>
            <div className='alerts'>
              <DateComponent date='1 zi'/>
              <DateComponent date='7 zile'/>
              <DateComponent date='14 zile'/>
              <DateComponent date='30 zile'/>
            </div>
            <ul className="reasons">
                <li>Este o aplicație care te ajută să ți evidența lucrurilor care expiră la o mașină.</li>
                <li>Este ușor de folosit.</li>
                <li>Primești notificare prin email când ceva urmează sa îți expire ceva, astfel poți <strong>evita amenzi nedorite.</strong></li>
                <li>Ținem evidența când expiră: rovinieta, asigurarea, ITP-ul, trusa medicală și extinctorul.</li>
                <li>Poți adăuga până la 10 mașini gratuit (pentru mai multe ne puteți contacta prin email).</li>
                <li>Ai opțiunea de a vedea în ordine cronologică ce urmează să expire.</li>
                <li>Totul depinde de tine, dacă adaugi informații corecte noi avem grija să primești un email de notificare cu o zi, 7 zile, 14 zile și 30 zile înainte să îți expire ceva.</li>
            </ul>
            <div className='cta-wrapper'>
              <button className='btn-add-car'><a href='/registration'>Înregistrare</a></button>
            </div>
        </div>
    );
}

export default Home;
