import React, { useState } from 'react';
import {
    withRouter
  } from 'react-router-dom'

import { validEmail, minLengthSix, maxLength256 } from "../../../utilities/formValidations";
import TextInputField from '../../common/formFields/TextInputField';
import { sendForgotEmail } from "../../../utilities/api";
import { translate } from  "../../../utilities/translation"
import { useGlobalState } from "../../../state/useGlobalState";
import { notify } from "../../../utilities/notifications";

const RegistrationForm = ( { history }) => {
    const [ resetPasswordDetails, setResetPasswordDetails ] = useState( {
        email: "",
        errors: ["email"]
    } );

    const handleFieldChange = ( property, value, error ) => {
        const newDetails = Object.assign( {}, resetPasswordDetails );
        newDetails[ property ] = value;
        const hasError = newDetails['errors'].find( field => property===field)
        if( error && !hasError ) {
            newDetails["errors"].push( property );
        }
        if( !error && hasError ) {
            newDetails["errors"] = newDetails["errors"].filter( field => field !== property );
        }
        setResetPasswordDetails( newDetails );
    };

    const handleSave = ( ) => {
        if( !resetPasswordDetails.errors.length ) {                     
            sendForgotEmail( { email: resetPasswordDetails.email } ).then( 
                ( succes ) => {
                    notify( translate("Notifications:ForgotPasswordEmailSent"))
                    history.push( "/login");
                },
                ( error ) => console.log( "there was an error ", error )
            )
            // console.log( loginDetails );
        }
    }

    const [ state, dispatch ] = useGlobalState();

    return (
        <div className="form-container">
            <div className="text-fields-container">
                <TextInputField
                    fieldLabel="Email"
                    handleFieldChange={handleFieldChange}
                    fieldName="email"
                    validations={ [ validEmail ] }
                    placeholder="email@email.com"
                />
            </div>
            <div>
                <button
                    className="btn-add-car"
                    onClick={handleSave}
                >
                {translate("Button:SendEmail")}
                </button>
            </div>
        </div>
    );
};

export default withRouter(RegistrationForm);
