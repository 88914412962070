const ONE_MONTH = 2592000000 // 30 days

function lessThen30Days( timestamp ) {
    const futureDate = new Date().getTime() + ONE_MONTH;
    return timestamp < futureDate;
}

function betweenOneAndThreeMonths( timestamp ) {
    const oneMonth = new Date().getTime() + ONE_MONTH;
    const threeMonths = new Date().getTime( ) + ( ONE_MONTH * 3 );

    return oneMonth < timestamp && timestamp < threeMonths;
}

function dateStringToTimestamp( date ) {
    console.log( "Date", date );
    if( !date ){
        return "";
    }
    const day = date.getDate( );
    const month = date.getMonth( );
    const year = date.getFullYear( );
    console.log(" Date ", `${year}-${month}-${day}` );

    return new Date( year, month, day ).getTime();
}

function timestampToDateString( timestamp ) {
    if( !timestamp ) {
        return "";
    }
    const date = new Date( timestamp );
    const day = date.getDate( );
    const month = date.getMonth( ) + 1;
    const year = date.getFullYear( );
    const newDay =  day > 9 ? day : `0${ day }`;
    const newMonth = month > 9 ? month : `0${ month }`;
    
    return `${ newDay }-${ newMonth }-${ year }`;
}

function carEventsToArray( car ) {
    const { carNumber, id } = car;
    const arrayOfEvents = car.events.reduce( ( acc, event ) => {
        if( event.value !== null ) {
            return [ ...acc, { 
                carNumber,
                id,
                event
            } ];
        }
        return acc;
        }, [] );
    return arrayOfEvents;
}
function getEventLists( cars ) {
    console.log( "getEventLists  ", cars );
    const nn = cars.reduce( ( acc, car ) => {
        const carEvents = carEventsToArray(car);
        return [ ...acc, ...carEvents ];
    }, []);
    console.log( "nn", nn );
    return [ ...nn].sort(byEvent);
}

function byEvent( a, b ) {
    return a.event.value > b.event.value;
}

export {
    dateStringToTimestamp,
    timestampToDateString,
    getEventLists,
    lessThen30Days,
    betweenOneAndThreeMonths,
}