import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { timestampToDateString } from "../../../utilities/helpers";
import { translate } from "../../../utilities/translation";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#F28444',
    color: "white",
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: '1px',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: "rgb(242, 132, 68, 0.1)",
    },
    margin: "15px",
    backgroundColor: "rgb(107, 167, 191, 0.2)",
  },
}))(TableRow);

function createData( { carNumber, event: { key: type, value: date }, id } ) {
  
  return { 
    carNumber,
    type,
    date: timestampToDateString(date),
    id
   };
}

const imageMapping = {
  "vignette": "vignette-blue",
  "medicalKit": "medical-kit-blue",
  "tehnicalInspection": "tehnical-inspection-blue",
  "insurance": "insurance-blue",
  "extinguisher": "extinguisher-blue"
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 400,
  },
}));

const ExpiringTable = ({ list, history }) => {
  const classes = useStyles();
  const getImage = ( name )=> <img src={`/images/${name}.png`} width="25px" height="25px" alt={name}/>
  const rows = list.map( event => createData(event) );
  const handleCarClick = ( id ) => () => history.push(`/edit-car/${id}`);
  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">{ translate("Form:CarNumber")}</StyledTableCell>
            <StyledTableCell align="center">Tip</StyledTableCell>
            <StyledTableCell align="center">Dată Expirare</StyledTableCell>
            <StyledTableCell align="center">
              Edit
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <StyledTableRow key={`${row.type}${row.date}`}>
              <StyledTableCell component="th" scope="row" align="center">
                {row.carNumber}
              </StyledTableCell>
              <StyledTableCell align="center"><div className="expiring-table-type">{ getImage(imageMapping[row.type] )}</div><div> { translate( `Global:${row.type}`) }</div></StyledTableCell>
              <StyledTableCell align="center">{row.date}</StyledTableCell>
              <StyledTableCell align="center">
              <button className='btn-add-car' onClick={ handleCarClick(row.id) }>
                  Edit
                </button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default withRouter(ExpiringTable);