import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";

import { timestampToDateString } from "../../../utilities/helpers";
import { translate } from "../../../utilities/translation";


function createData( { carNumber, event: { key: type, value: date }, id } ) {
  
  return { 
    carNumber,
    type,
    date: timestampToDateString(date),
    id
   };
}

const imageMapping = {
    "vignette": "vignette-blue",
    "medicalKit": "medical-kit-blue",
    "tehnicalInspection": "tehnical-inspection-blue",
    "insurance": "insurance-blue",
    "extinguisher": "extinguisher-blue"
  }

const ExpiringListMobile = ({ list, history }) => {
  const getImage = ( name )=> <img src={`/images/${name}.png`} width="25px" height="25px" alt={name}/>
  const rows = list.map( event => createData(event) );
  const handleCarClick = ( id ) => () => history.push(`/edit-car/${id}`);
  return (
    <div>
         {
             rows.map(row => (
                <div className='mobile-expiring-container'>
                <div>{row.carNumber}</div>
                <div>
                    <div>
                        { getImage(imageMapping[row.type] )}
                    </div>
                    <div> { translate( `Global:${row.type}`) }</div>
                </div>
                <div>
                    {row.date}
                </div>
                <div className="car-list-actions">
                  <button className='btn-add-car' onClick={ handleCarClick(row.id) }>
                    Edit
                  </button>
                </div>
                </div>
             ))
         }

    </div>
  );
}

export default withRouter(ExpiringListMobile);