import React, { useState } from 'react';
import {
    withRouter
  } from 'react-router-dom'

import { validEmail, minLengthSix, maxLength256 } from "../../../utilities/formValidations";
import TextInputField from '../../common/formFields/TextInputField';
import { notify } from  "../../../utilities/notifications";
import { register, login } from "../../../utilities/api";
import { translate } from  "../../../utilities/translation"
import { useGlobalState } from "../../../state/useGlobalState";

const RegistrationForm = ( { history }) => {
    const [ registrationDetails, setRegistrationDetails ] = useState( {
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        addedDate: new Date(),
        errors: ["email", "password", "firstName", "lastName"]
    } );

    const handleFieldChange = ( property, value, error ) => {
        const newDetails = Object.assign( {}, registrationDetails );
        newDetails[ property ] = value;
        const hasError = newDetails['errors'].find( field => property===field)
        if( error && !hasError ) {
            newDetails["errors"].push( property );
        }
        if( !error && hasError ) {
            newDetails["errors"] = newDetails["errors"].filter( field => field !== property );
        }
        setRegistrationDetails( newDetails );
    };
    const [ state, dispatch ] = useGlobalState();

    const handleSave = ( ) => {
        if( !registrationDetails.errors.length ) {
            const { errors, addedDate, ...user } = registrationDetails;
            register( user ).then( 
                ( succes ) => {
                   login( { email: user.email, password: user.password } ).then(
                       ( success ) => {
                            dispatch( { type: "LOGIN" })
                            history.push("/cars");
                            notify( translate("Notifications:RegistrationSuccessfull"), "success" );
                       },
                       error => { 
                            history.push("/cars")
                       }
                   )
                },
                ( error ) => {
                    console.log( "there was an error ", error );
                    notify( translate("Notifications:RegistrationFailed"), "warning")
                }
            )
        } else {
            notify( translate("Notifications:CheckFormFields"), "warning")
        }
    }

    return (
        <div className="form-container">
            <div className="text-fields-container">
                <TextInputField
                    fieldLabel="Email"
                    handleFieldChange={handleFieldChange}
                    fieldName="email"
                    validations={ [ validEmail ] }
                    placeholder="email@email.com"
                    required
                />
                <TextInputField
                    fieldLabel={translate("Form:Password")}
                    handleFieldChange={handleFieldChange}
                    fieldName="password"
                    type="password"
                    validations={ [ minLengthSix ] }
                    placeholder="introdu parola"
                    required
                />
                <TextInputField
                    fieldLabel={translate("Form:FirstName")}
                    handleFieldChange={handleFieldChange}
                    fieldName="firstName"
                    validations={ [ maxLength256 ] }
                    placeholder="Iron"
                    required
                />
                <TextInputField
                    fieldLabel={translate("Form:LastName")}
                    handleFieldChange={handleFieldChange}
                    fieldName="lastName"
                    validations={ [ maxLength256 ] }
                    placeholder="Man"
                    required
                />
               <div>
                   <input type='checkbox' name='terms'/>Am citit și sunt de acord cu <a href='/terms-and-conditions' target='_blank'>termenii şi condiţiile serviciului</a> și <a href='/privacy-policy' target='_blank'>politica de confidenţialitate</a>.
               </div>
               <p>
               {translate("Form:Required")}
               </p>
            </div>
            <div>
                <button
                    className="btn-add-car"
                    onClick={handleSave}
                >
                {translate("Button:Register")}
                </button>
            </div>
        </div>
    );
};

export default withRouter(RegistrationForm);
