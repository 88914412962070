import { getToken, setToken } from "./localStorageHelper";

const api = "/api";
// const api= "http://10.255.248.53:3030";

async function apiFetch( url, method,  data = {} ) {
    const token = await getToken( );
    const apiUrl = `${api}/${url}`;
    const standard = {
        method,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-Access-Token": token,
        },
    }
    const fullDetails = method === "GET" ? { ...standard } : { ...standard, body: JSON.stringify( data ) };
    // const body = method === "GET" ? { body: JSON.stringify( data ) } : { };
    // console.log( "\n --------  API Call START-----" );
    // console.log( "apiUrl ", apiUrl );
    // console.log( "token ", token );
    // console.log( "method ", method );
    // console.log( "Data ", data );
    // console.log( "\n -------- API Call END-----" );
    return fetch( apiUrl, fullDetails )
    .then( async response => {
        const res = await response.json();
        // console.log( "Response success: ", res ); 
        if( res.success === false ) {
            return Promise.reject( {
                success: false,
                error: res.message || " error",
            } );
        }
        if( [ "users/login", "users/register", "users/facebook-login" ].includes( url ) && res.data.token ) {
            // console.log("SET TOKEN ", res.data.token );
            await setToken( res.data.token );
        }
        return res;
    })
    .catch( error => {
        // console.log( "Aici -> ", error );
        return Promise.reject( {
            success: false,
            error
        } ) } );
}

function register( user ) {
    return apiFetch( "users/register", "POST", user );
}

function getUserDetails( ) {
    return apiFetch( "users/me", "GET" );
}

function editUser( user ) {
    return apiFetch( `users`, "PUT", user )
}

function login( user ) {
    return apiFetch( "users/login", "POST", user);
}

function facebookLogin( user ) {
    return apiFetch( "users/facebook-login","POST", user );
}

function saveCar( car ) {
    return apiFetch( "cars", "POST", car );
}

function editCar( car, id ) {
    return apiFetch( `cars/${ id }`, "PUT", car );
}

function deleteCar( carId ) {
    return apiFetch( `cars/${ carId }`, "DELETE" );
}

function getCars( ) {
    return apiFetch( "cars", "GET" );
}

function getCarById( carId ) {
    return apiFetch( `cars/${ carId }`, "GET" );
}

function getExpirationEvents() {
    return apiFetch( "cars/expiring-events", "GET" );
}

function sendForgotEmail( email ) {
    return apiFetch( "users/forgot-password", "POST", email );
}
function sendContactEmail( details ) {
    return apiFetch( "users/send-contact-email", "POST", details );
}


export {
    login,
    facebookLogin,
    register,
    editUser,
    getUserDetails,
    saveCar,
    deleteCar,
    getCars,
    getCarById,
    getExpirationEvents,
    sendForgotEmail,
    sendContactEmail,
    editCar
}