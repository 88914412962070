import React from "react";

const Terms = () => (
    <div>
        <h1>Termeni și condiții</h1>
        <p>Bine ați venit pe Expiro!</p>
        <p>
            Acești termeni și condiții conturează regulile și regulamentele de utilizare a site-ului web Expiro, aflat pe site-ul http://expiro.ro.
        </p>
        <p>
            Accesând acest site web, presupunem că acceptați acești termeni și condiții. Nu continuați să folosiți Expiro dacă nu sunteți de acord să luați toți termenii și condițiile menționate pe această pagină. Termenii și condițiile noastre au fost create cu ajutorul Generatorului de Termeni și Condiții și a Generatorului de Termeni și Condiții.
        </p>
        <p>
            Următoarea terminologie se aplică acestor Termeni și condiții, Declarație de confidențialitate și Aviz de exonerare de răspundere și tuturor acordurilor: „Client”, „Tu” și „Dvs.” se referă la tine, persoana care se conectează pe acest site web și respectă termenii și condițiile companiei. „Compania”, „Noi”, „Noi”, „Noi” și „Noi”, se referă la Compania noastră. „Parte”, „Părți” sau „Noi” se referă atât la Client, cât și la noi înșine. Toți termenii se referă la oferta, acceptarea și considerarea plății necesare pentru a efectua procesul de asistență către Client în cel mai adecvat mod în scopul expres de a satisface nevoile Clientului în ceea ce privește furnizarea serviciilor declarate ale Companiei, în conformitate cu și sub rezerva legii în vigoare a Olandei. Orice utilizare a terminologiei de mai sus sau a altor cuvinte în singular, plural, majuscule și / sau el / ea, sunt considerate interschimbabile și, prin urmare, se referă la acestea.
        </p>
        <h2>Cookies</h2>
        <p>
            Utilizăm utilizarea cookie-urilor. Accesând Expiro, ați acceptat să utilizați cookie-uri în acord cu Politica de confidențialitate a Expiro.
            Majoritatea site-urilor web interactive utilizează cookie-uri pentru a ne permite să regăsim detaliile utilizatorului pentru fiecare vizită. Cookie-urile sunt utilizate de site-ul nostru web pentru a permite funcționalitatea anumitor zone pentru a facilita persoanelor care vizitează site-ul nostru web. Unii dintre partenerii noștri afiliați / publicitari pot utiliza, de asemenea, cookie-uri.
        </p>
        <h2>Licență</h2>
        <p>
            Cu excepția cazului în care se prevede altfel, Expiro și / sau autorizatorii săi dețin drepturile de proprietate intelectuală pentru toate materialele de pe Expiro. Toate drepturile de proprietate intelectuală sunt rezervate. Puteți accesa acest lucru de la Expiro pentru propria utilizare personală supusă restricțiilor stabilite în acești termeni și condiții.
        </p>
        <p>
            Expiro.ro NU va vinde sau oferi gratuit datele dumneavostră (email, nume, număr mașină, etc... ). Vom încerca să păstram confidențialitatea datelor dumneavoastră cât putem de bine.
        </p>
    </div>
);

export default Terms;