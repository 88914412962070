import React from 'react';
import { getExpirationEvents } from "../../../utilities/api";
import { getEventLists } from "../../../utilities/helpers";
import ExpiringTable from "./ExpiringTable";
import ExpiringListMobile from "./ExpiringListMobile";

import { translate } from '../../../utilities/translation';

class Expiring extends React.Component {
    state = {
        events: [],
        mobileView: null,
        loading: false,
    }

    componentDidMount( ) {
        this.setState({ loading: true });
        getExpirationEvents( ).then( 
            (sucess ) => {
                this.setState( {
                    events: getEventLists( sucess.data ),
                    mobileView: window.innerWidth < 600,
                    loading: false,
                });
            },
            error => {
                console.log( "events ", error )
                this.setState({ loading: false });
            }
        )
    }

    render( ) {
        const { events, mobileView, loading } = this.state;
        const Component = mobileView ? ExpiringListMobile : ExpiringTable;
        const list =  events.sort( (a, b) => a.event.value - b.event.value );
        const expiringList = events.length ? <Component list={list} /> : "Nu ai nici o mașină adăugată";

        return (
            <div className="expiring-container">
                { loading && <img src="images/loading.gif" />}
                { !loading && expiringList }
            </div>
        )
    }
}

export default Expiring;
