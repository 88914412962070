import React, { useState, useEffect } from 'react';
import {
    withRouter
  } from 'react-router-dom'


import { validEmail, minLengthSix, maxLength256 } from "../../../utilities/formValidations";
import TextInputField from '../../common/formFields/TextInputField';
import {  editUser, getUserDetails } from "../../../utilities/api";
import { translate } from "../../../utilities/translation";
import { notify } from "../../../utilities/notifications";

const EditProfile = ( { history }) => {
    const [ profileDetails, setProfileDetails ] = useState( {
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        addedDate: new Date(),
        errors: []
    } );


    useEffect( ( ) => {
       const fetchData = async () => {
        const details = await getUserDetails( );
        const { email, lastName, firstName } = details.data;
        const newDetails = Object.assign( {}, profileDetails);
        newDetails["email"] = email;
        newDetails["lastName"] = lastName;
        newDetails["firstName"] = firstName;
        setProfileDetails( newDetails );
       };
       fetchData();
    }, [])

    const handleFieldChange = ( property, value, error ) => {
        const newDetails = Object.assign( {}, profileDetails );
        newDetails[ property ] = value;
        const hasError = newDetails['errors'].find( field => property===field)
        if( error && !hasError ) {
            newDetails["errors"].push( property );
        }
        if( !error && hasError ) {
            newDetails["errors"] = newDetails["errors"].filter( field => field !== property );
        }
        setProfileDetails( newDetails );
    };

    const handleSave = ( ) => {
        if( !profileDetails.errors.length ) {                     
            editUser( profileDetails ).then( 
                ( succes ) => {
                    notify( translate("Notifications:ProfileSaved"), "success");
                },
                ( error ) => {
                    console.log( "there was an error ", error );
                    notify( translate("Notifications:ProfileSaveFailed"), "warning");
                }
            )
        } else {
            notify( translate("Notifications:CheckFormFields"), "warning");
        }
    }

    const { email, firstName, lastName } = profileDetails;
    return (
        <div className="form-container">
          { profileDetails.email && (<><div className="text-fields-container">
                <TextInputField
                    fieldLabel={ translate("Form:Email")}
                    handleFieldChange={handleFieldChange}
                    fieldName="email"
                    validations={ [ validEmail ] }
                    placeholder="email@email.com"
                    value={ email }
                />
                <TextInputField
                    fieldLabel={translate("Form:NewPassword")}
                    handleFieldChange={handleFieldChange}
                    fieldName="password"
                    type="password"
                    validations={ [ minLengthSix ] }
                    placeholder="introdu parola"
                />
                <TextInputField
                    fieldLabel={translate("Form:FirstName")}
                    handleFieldChange={handleFieldChange}
                    fieldName="firstName"
                    validations={ [ maxLength256 ] }
                    placeholder="Iron"
                    value={firstName}
                />
                <TextInputField
                    fieldLabel={ translate("Form:LastName")}
                    handleFieldChange={handleFieldChange}
                    fieldName="lastName"
                    validations={ [ maxLength256 ] }
                    placeholder="Man"
                    value={lastName}
                />
            </div>
            <div>
                <button
                    className="btn-add-car"
                    onClick={handleSave}
                >
                {translate("Button:Save")}
                </button>
            </div></>) 
        }
        </div>
    );
};

export default withRouter(EditProfile);
