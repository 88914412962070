import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./dictionaries/en";
import ro from "./dictionaries/ro";

const resources = {
  en,
  ro
};

export const lng = "ro";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "ro",
    languages: ["ro", "en"],

    keySeparator: false, // we do not use keys in form messages.welcome
    debug: true,
    defaultNS: "global",
    
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  i18n.on('languageChanged', function(lng) {
    // E.g. set the moment locale with the current language
    console.log( "Vasile ");

    // then re-render your app
    // app.render();
});

  export const reInit = ( lang ) => {

  i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: lang,
    languages: ["ro", "en"],

    keySeparator: false, // we do not use keys in form messages.welcome
    debug: true,
    defaultNS: "global",
    
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
  }

  export default i18n;