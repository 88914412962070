import React from 'react';
import ContactForm from './ContactForm';

const Contact = () => (
    <>
        <h1>Contact</h1>
        <p>
            Dacă ai o întrebare sau o propunere de business ne poți contacta folosind formularul de mai jos.
        </p>
        <ContactForm />
    </>
);

export default Contact;
