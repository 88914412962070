import React from 'react';

import LoginForm from "./LoginForm";
import FacebookLoginButton from "../../common/Facebook/FacebookLogin";

const Login = () => (
    <>
        <div>
            <FacebookLoginButton />
        </div>
        <div>
        <LoginForm />
        </div>
    </>
);

export default Login;
