import React from 'react';
import { withRouter } from 'react-router-dom';
import CookieConsent from '../CookieConsent';

const Footer = ({ history }) => {
    return (
        <div className="footer-wrapper">
            <div className="footer-content">
                <h3>Expiro</h3>
                <a className="menu-item" href="/terms-and-conditions" target='_blank'>
                    Termeni și Condiții de utilizare
                </a>
                <a className="menu-item" href="/privacy-policy" target='_blank'>
                    Politică de confidențialitate
                </a>
                <div className="logo-container">
                    <img src="/images/logo.png" width="25px" height="25px" />
                </div>
            </div>
            <CookieConsent />
        </div>
    );
};

export default withRouter(Footer);
