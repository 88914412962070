import React from "react";

const MobileMenu = ( { menuItems }) => {
    const openNav = () => {
        document.getElementById("myNav").style.height = "100%";
    }
      
    const closeNav = () => {
        document.getElementById("myNav").style.height = "0%";
      }
    return <div className="mobile-menu">
        <div id="myNav" className="overlay">
            <a className="closebtn" onClick={closeNav}>&times;</a>
            <div className="overlay-content" onClick={closeNav}> 
                { menuItems }
            </div>
        </div>
        <span onClick={openNav}>&#9776;</span>
    </div>
}

export default MobileMenu;