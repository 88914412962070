import React, { useState } from 'react';
import { getCookieConsent, setCookieConsent } from '../../utilities/localStorageHelper';

const CookieConsent = ( ) => {
    const [isConsent, setConsent] = useState(getCookieConsent());

    const handleAccept = () => {
        setConsent(true)
        setCookieConsent(true)
    }

    return !isConsent && <div className="cookie-container">
      <p>
        Expiro folosește cookies și alte tehnologii pentru a îmbunătăți experiența pe site-ul nostru.
      </p>
      <p>
        Mai multe informatii poți găsi aici:{' '}
        <a href="/privacy-policy" target="_blank">
          <span className="link">Politica de confidențialitate.</span>
        </a>
      </p>
      <p>
      Prin acceptarea cookie-urilor, ne ajuți să utilizăm această tehnologie pe site.
      </p>
      <button  className='btn-add-car' onClick={handleAccept}>Accept</button>
  </div>
}

export default CookieConsent;