import React, { useState } from 'react';
import {
    withRouter
  } from 'react-router-dom'

import { validEmail, maxLength4048 } from "../../../utilities/formValidations";
import TextInputField from '../../common/formFields/TextInputField';
import TextAreaField from "../../common/formFields/TextAreaField";
import { notify } from  "../../../utilities/notifications";
import { sendContactEmail } from "../../../utilities/api";
import { translate } from  "../../../utilities/translation"
import { useGlobalState } from "../../../state/useGlobalState";

const ContactForm = ( { history }) => {
    const [ messageDetails, setMessageDetails ] = useState( {
        email: "",
        message: "",
        addedDate: new Date(),
        errors: ["email", "message"]
    } );

    const handleFieldChange = ( property, value, error ) => {
        const newDetails = Object.assign( {}, messageDetails );
        newDetails[ property ] = value;
        const hasError = newDetails['errors'].find( field => property===field)
        if( error && !hasError ) {
            newDetails["errors"].push( property );
        }
        if( !error && hasError ) {
            newDetails["errors"] = newDetails["errors"].filter( field => field !== property );
        }
        setMessageDetails( newDetails );
    };
    const [ state, dispatch ] = useGlobalState();

    const handleSave = ( ) => {
        if( !messageDetails.errors.length ) {
            const { errors, addedDate, ...details } = messageDetails;
            sendContactEmail( details )
            .then( 
                () => {
                    history.push( "/");
                    notify( "Email trimis cu succes", "info")
                },
                () => {
                    notify( "Încearca din nou, email nu a fost trimis", "warn")
                }
            );
        } else {
            notify( translate("Notifications:CheckFormFields"), "warning")
        }
    }

    return (
        <div className="form-container">
            <div className="text-fields-container">
                <TextInputField
                    fieldLabel="Email"
                    handleFieldChange={handleFieldChange}
                    fieldName="email"
                    validations={ [ validEmail ] }
                    placeholder="email@email.com"
                    required
                />
                <TextAreaField
                    fieldLabel={translate("Form:Message")}
                    handleFieldChange={handleFieldChange}
                    fieldName="message"
                    validations={ [ maxLength4048 ] }
                    placeholder="Mesaj...."
                    required
                />
               <p>
               {translate("Form:Required")}
               </p>
            </div>
            <div>
                <button
                    className="btn-add-car"
                    onClick={handleSave}
                >
                {translate("Button:SendEmail")}
                </button>
            </div>
        </div>
    );
};

export default withRouter(ContactForm);
