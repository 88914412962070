import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";

import { timestampToDateString } from "../../../utilities/helpers";
import { translate } from "../../../utilities/translation";



function createData( { id, carNumber, brand, extinguisher, medicalKit, vignette, insurance, tehnicalInspection } ) {
  
    return {
      id,
      carNumber,
      brand,
      extinguisher: extinguisher ? timestampToDateString(extinguisher): "-",
      medicalKit: medicalKit ? timestampToDateString(medicalKit): "-",
      vignette: vignette ? timestampToDateString(vignette): "-",
      insurance: insurance ? timestampToDateString(insurance): "-",
      tehnicalInspection: tehnicalInspection ? timestampToDateString(tehnicalInspection): "-",
     };
}

const imageMapping = {
    "vignette": "vignette-blue",
    "medicalKit": "medical-kit-blue",
    "tehnicalInspection": "tehnical-inspection-blue",
    "insurance": "insurance-blue",
    "extinguisher": "extinguisher-blue"
  }

const CarListMobile = ({ list, history }) => {
  const getImage = ( name )=> <img src={`/images/${name}.png`} width="25px" height="25px" alt={name}/>
  const rows = list.map( event => createData(event) );
  const handleCarClick = ( id ) => () => history.push(`/edit-car/${id}`);
  return (
    <div>
         {
             rows.map(row => (
                <div className='mobile-car-container'>
                    <div className='left'>{translate('Form:CarNumber')}</div>
                    <div className='right'>{row.carNumber}</div>
                    <div className='left'>{ translate('Global:vignette')}</div>
                    <div className='right'>{row.vignette}</div>
                    <div className='left'>{ translate('Global:tehnicalInspection')}</div>
                    <div className='right'>{row.tehnicalInspection}</div>
                    <div className='left'>{ translate('Global:medicalKit')}</div>
                    <div className='right'>{row.medicalKit}</div>
                    <div className='left'>{ translate('Global:extinguisher')}</div>
                    <div className='right'>{row.extinguisher}</div>
                    <div className='left'>{ translate('Global:insurance')}</div>
                    <div className='right'>{row.insurance}</div>
                    <button
                        className="btn-add-car"
                        onClick={handleCarClick(row.id) }
                    >
                    {translate("Button:Edit") }
                    </button>
                </div>
             ))
         }
    </div>
  );
}

export default withRouter(CarListMobile);