export default {
    Global: {
        medicalKit: "Trusă medicală",
        tehnicalInspection: "Inspecție Tehnică",
        insurance: "Asigurare",
        extinguisher: "Extinctor",
        vignette: "Rovinietă",
    },
    Form: {
        FirstName: "Prenume",
        FirstNameError: "Adaugă un prenume corect",
        FirstNamePlaceHolder: "Ion",
        LastName: "Nume",
        LastNameError: "Adaugă un nume corect",
        LastNamePlaceHolder: "Popescu",
        Phone: "Telefon",
        PhoneError: "Adaugă un număr de telefon valid",
        PhonePlaceHolder: "0771 333 333",
        Password: "Parola",
        PasswordError: "Adaugă o parolă mai complexă",
        NewPassword: "Parolă Nouă",
        PasswordPlaceHolder: "parola...",
        ReTypePassword: "Confirmare Parolă",
        ReTypePasswordError: "Confirmarea parolei trebuie să fie egală",
        ReTypePasswordPlaceHolder: "Aceeași parolă cu cea de mai sus",
        Email: "Email",
        EmailError: "Adaugă un email corect",
        EmailPlaceHolder: "example@example.com",
        CarNumber: "Număr Mașină",
        CarNumberPlaceHolder: "CJ-85-MDL",
        CarNumberError: "Adaugă un număr de mașina corect",
        Brand: "Marcă",
        BrandPlaceHolder: "Audi",
        BrandError: "Adaugă o marcă corectă",
        DatePlaceHolder: "15.06.2021",
        Description: "Descriere",
        DescriptionPlaceHolder: "orice descriere.. ",
        DescriptionError: "Adaugă o descriere corectă",
        InsuranceExpiration: "Asigurarea expiră la",
        VignetteExpiration: "Rovinieta expiră la",
        TehnicalInspectionExpiration: "Inspecția tehnică expiră la",
        ExtinguisherExpiration: "Extinctorul expiră la",
        MedicalKitExpiration: "Trusa medicală expiră la",
        ProfileSaved: "Profil salvat",
        Profile: "Profil",
        Car: "Mașina",
        CarSaved: "Datele mașini salvate",
        Required: "* date obligatorii",
        Message: "Mesaj"
    },
    ScreenTitle: {
        Home: "Acasă",
        Add: "Adaugă",
        Cars: "Mașini",
        CarDetails: "Detaliile mașinii",
        Edit: "Editează",
        Profile: "Profil",
        Login: "Login",
        Registration: "Înregistrare",
        ForgotPassword: "Recupareare Parolă"
    },
    Button: {
        Login: "Login",
        Save: "Salvează",
        SendEmail: "Trimite email",
        Register: "Inregistrare",
        ForgotPassword: "Ai uitat parola?",
        AddCar: "Adaugă Mașină",
        DeleteCar: "Șterge mașina",
        ShowTC: "Arată T&C",
        Ok: "Ok"
    },
    Navigation: {
        Home: "Acasă",
        AddCar: "Adaugă Mașină",
        Cars: "Mașini",
        Edit: "Editează",
        Profile: "Profil",
        ExpirationList: "Listă Expirare",
        Login: "Autentificare",
        Logout: "Ieșire",
        Register: "Înregistrare",
    },
    Login: {
        Failed: "Logare nereușită",
        Description: "Verifică datele de logare",
        CheckEmailForPassword: "Verifică adresa de email pentru noua parolă",
        BackToLogin: "Inapoi la login",
    },
    Registration: {
        TermsAndConditions: "Am citit și sunt de acord cu termenii și condițiile de utilizare disponibile apasând \"Arată T&C\" de mai jos.",
        TermsAndConditionsError: "Trebuie să accepți termenii și condițiile de utilizare pentru a te putea inregistra."
    },
    Notifications: {
        ProfileSaved: "Profil salvat cu succes.",
        ProfileSaveFailed: "Salvarea profilului a eșuat.",
        CarSaved: "Mașina a fost salvată cu succes.",
        CarSavedFailed: "Mașina NU a fost salvată.",
        CarDeletedSuccessfull: "Mașina a fost ștearsă.",
        CarDeletedFailed: "Mașina nu a fost ștearsă, incercă din nou.",
        RegistrationSuccessfull: "Înregistrare cu succes.",
        RegistrationFailed: "Înregistrare a eșuat, verifică datele și încearcă din nou.",
        LoginSuccessfull: "Logare cu succes.",
        LoginFailed: "Logare nereușită,  verifică datele și încercă din nou.",
        CheckFormFields: "Verifică datele din formular sa nu fie erori (câmpurile cu * sunt obligatorii)",
        ForgotPasswordEmailSent: "Noua parolă a fost trimisă pe email."
    }
}