import React,{ useState } from "react";

const TextInput = ( {
    fieldName, fieldLabel, handleFieldChange, classes, validations, type = "text", placeholder, value, required
} ) => {
    const [ textLabel, setTextLabel ] = useState( null );
    const [ hiddenSpan, hideSpan ] = useState( null );
    const [ errorText, setErrorText ] = useState( "" );

    const handleChange = event => {
        if( validations && validations.length ) {
            const errors = validations.map( validation => validation( event.target.value ) ).join(" ");
            setErrorText( errors );
            handleFieldChange( fieldName, event.target.value, errors.trim() );
        } else {
            handleFieldChange( fieldName, event.target.value, errorText.trim() );
        }

    };

    const composedFieldLabel = required ? `${fieldLabel} *`: fieldLabel;

    return (
        <div className="input-wrapper">
            <div className="field-error">{errorText}</div>
            <div className="text-input">
                <label>{composedFieldLabel}</label>
                <input 
                    onChange={handleChange}
                    placeholder={placeholder}
                    type={type}
                    value={value}
                />
            </div>
        </div>
    );
}

export default TextInput;