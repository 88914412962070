const setToken = ( token ) => localStorage.setItem( 'token', `${ token }` );

const getToken = () => localStorage.getItem( 'token' );

const deleteToken = () => localStorage.removeItem( 'token' );

const getCookieConsent = () => localStorage.getItem( 'cookie-consent') || false;

const setCookieConsent = ( cookieConsent ) => localStorage.setItem( 'cookie-consent', cookieConsent)

export { setToken, getToken, deleteToken, getCookieConsent, setCookieConsent };
