import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { dateStringToTimestamp } from "../../../utilities/helpers";

const theme = createMuiTheme( {
    palette: {
        primary: {
            light: '#fff',
            main: 'rgb(0, 144, 201)',
            dark: '#007a7a',
        },
    },
} );

const styles = {
    picker: {
        width: 180,
    },
    input: {
        fontSize: 14,
        fontFamily: 'Helvetica',
        color: '#071826',
        textAlign: 'center',
    },
};

const DateInputField = ( {
    classes, fieldLabel, screenSize, handleFieldChange, fieldName, value
} ) => {
    const [ dateLabel, setDateLabel ] = useState( null );
    const [ hiddenSpan, hideSpan ] = useState( null );
    const [ currentDate, setDate ] = useState( null );

    const handleChange = date => {
        const newDate = dateStringToTimestamp( new Date( date ) );
        handleFieldChange( fieldName, newDate );
        setDate( date );
    };

    const dateValue = value ? new Date(value) : currentDate;
    return (
        <div className="date-input">
            <span style={hiddenSpan}>{fieldLabel}</span>
            <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        className={classes.picker}
                        label={dateLabel}
                        format="dd-MM-yyyy"
                        InputProps={{
                            classes: {
                                input: classes.input,
                            },
                        }}
                        onChange={handleChange}
                        value={dateValue}
                        minDate={new Date()}
                    />
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </div>
    );
};

DateInputField.propTypes = {
    fieldName: PropTypes.string.isRequired,
    fieldLabel: PropTypes.string.isRequired,
    handleFieldChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    screenSize: PropTypes.number.isRequired,
};

export default withStyles( styles )( DateInputField );
